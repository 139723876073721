<template>
  <div class="photo">
    <div class="photo-info">
      <p class="date">{{ date }}</p>
      <p class="description">
        {{ description }}
      </p>
    </div>
    <img :src="require(`../assets/images/${photoSrc}`)" alt="{{ photoAlt }}" />
  </div>
</template>

<script>
export default {
  name: "Photo",
  props: {
    photoSrc: String,
    photoAlt: String,
    date: String,
    description: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.photo {
  position: relative;
  margin: 0 0 0.5rem 2rem;
  display: flex;
  align-items: flex-start;
}

.photo::after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: -25px;
  background-color: #fe2852;
  top: 50px;
  border-radius: 50%;
  z-index: 1;
}

.photo img {
  width: 100%;
}

.photo-info {
  display: none;
  padding: 2rem;
  color: #f1f1f1;
  border: 1px solid #fe2852;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.photo-info .date {
  margin: 0 0 1rem;
  background-color: #fe2852;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  font-weight: bold;
}

.photo-info .description {
  margin: 0;
  font-size: 1.25rem;
}

@media (min-width: 1440px) {
  .photo {
    margin: 0 0 2rem 2rem;
  }
  .photo img {
    width: 80%;
  }
  .photo-info {
    display: block;
    width: 20%;
  }
}
</style>
