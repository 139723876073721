<template>
  <div class="background">
    <h1 class="title">Build the future</h1>
    <p class="by">Jasper Veldhuizen</p>

    <div class="wave">
      <img src="./assets/images/wave-up.svg" />
    </div>
  </div>

  <img src="./assets/images/wave-down.svg" />

  <div class="about">
    <p><b>Welcome to my portfolio!</b> <br /> <small>See what I've been working on below</small></p>
  </div>

  <div class="collection">
    <div class="timeline">
      <Photo
        v-for="photo in photos"
        :key="photo.id"
        v-bind:photoSrc="photo.src"
        v-bind:photoAlt="photo.alt"
        v-bind:date="photo.date"
        v-bind:description="photo.description"
      />
    </div>
  </div>

  <div class="footer">
    <p>Made with &#10084; in Vue.js 3</p>
  </div>
</template>

<script>
import Photo from "./components/Photo.vue";

export default {
  name: "App",
  components: {
    Photo,
  },
  data() {
    return {
      photos: [
        { id: 1, src: "slider-image-1.png", alt: "XinsHosting", date: "2018", description: "XinsHosting" },
        { id: 2, src: "slider-image-3.jpg", alt: "Jomi", date: "2018", description: "Jomi All For Fun Rental" },
        { id: 3, src: "slider-image-4.jpg", alt: "IDBKinesiologie", date: "2018", description: "IDBKinesiologie" },
        { id: 4, src: "slider-image-2.jpg", alt: "PentestHub", date: "2019", description: "PentestHub" },
        { id: 5, src: "slider-image-5.png", alt: "Bottlesites", date: "2019", description: "Bottlesites" },
        { id: 6, src: "slider-image-9.png", alt: "Bottlesites", date: "2019", description: "Bottlesites reviews" },
        { id: 7, src: "slider-image-10.png", alt: "", date: "2019", description: "Oud portfolio" },
        { id: 8, src: "slider-image-7.png", alt: "Comprehand", date: "2020", description: "Comprehand" },
        { id: 9, src: "slider-image-8.png", alt: "", date: "2020", description: "Comprehand pakketten" },
        { id: 10, src: "slider-image-6.png", alt: "Bruna Pijnacker", date: "2021", description: "Bruna Pijnacker" },
        { id: 11, src: "slider-image-11.png", alt: "StoryCrafting App", date: "2022", description: "StoryCrafting App (HHS)" },
        { id: 12, src: "slider-image-12.png", alt: "H5mag App", date: "2022", description: "H5mag App" },
        { id: 13, src: "slider-image-13.png", alt: "At Julien", date: "2023", description: "At Julien" },
      ],
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
#app {
  font-family: "Quicksand", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

body {
  background-color: #212121;
  margin: 0;
}

.background {
  /* background: url("~@/assets/images/bg.jpg") center no-repeat; */
  background-size: cover;
  position: relative;
  width: 100%;
  height: 75%;
}

h1.title {
  margin: 3rem 0 2rem;
  color: #f1f1f1;
  /* border-top: 1px solid #fe2852; */
  /* border-bottom: 1px solid #fe2852; */
  display: inline-block;
  padding: 1rem;
}

p.by {
  color: #f1f1f1;
  font-weight: bold;
  z-index: 1000;
  position: relative;
}

.wave img {
  position: absolute;
  bottom: 0;
  left: 0;
}

.about {
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding: 0 2rem;
}

.about p {
  border: 1px solid #fe2852;
  color: #f1f1f1;
  display: inline-block;
  font-size: 1.5rem;
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 0;
}

.collection {
  width: 100%;
}

.timeline {
  position: relative;
  margin: 0 auto;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #fe2852;
  top: 0;
  bottom: 0;
  left: 1rem;
  margin-left: -3px;
}

.footer {
  padding: 2rem;
  color: #f1f1f1;
}

@media (min-width: 425px) {
  h1.title {
    padding: 1rem 3rem;
    font-size: 3rem;
  }

  p.by {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  h1.title {
    margin-top: 6rem;
    font-size: 5rem;
  }

  p.by {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .background {
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .about {
    margin-bottom: 5rem;
  }
}

@media (min-width: 1440px) {
  .about {
    margin-bottom: 7rem;
  }

  .about p {
    font-size: 2rem;
  }
}
</style>
